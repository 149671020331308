import { useForm } from 'react-hook-form'

import { IdsIcon, IdsIconButton } from '@ids/react'
import { Navigation, If } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'

import Shimmer from 'components/Shimmer'

import './diagnosis.scss'
import useDiagnosis from './hooks'

const Diagnosis = ({ pageContext }) => {
  const { handleSubmit } = useForm()

  const { changeLeadData } = useLead()

  const { onSubmit, onFeedbackCorretly, feedback, diagnosis, submitLoading } =
    useDiagnosis({
      changeLeadData
    })

  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={submitLoading}
        renderIf={
          <>
            <Shimmer
              margin="10px 0 50px 0"
              widthDesktop="85%"
              height="64px"
              heightDestkop="30px"
            />
            <Shimmer margin="0 0 30px 0" height="65px" />
            <Shimmer
              margin="0 0 300px 0"
              marginDesktop="0 0 120px 0"
              width="40%"
              height="72px"
            />
            <Shimmer widthDesktop="50%" height="50px" />
          </>
        }
        renderElse={
          <>
            <h1 className="proposal-title">
              Confira a análise personalizada da sua necessidade
            </h1>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="diagnosis__wrapper">
                <IdsIcon size="M" variant="neutral">
                  {diagnosis?.icon}
                </IdsIcon>
                <div className="diagnosis_text-wrapper">
                  <p className="diagnosis__title">{diagnosis?.name}</p>
                  <p className="diagnosis__subtitle">
                    {diagnosis?.description}
                  </p>
                </div>
              </div>
              <p className="diagnosis__question-text">
                A análise está correta?
              </p>
              <div className="diagnosis__icon-wrapper">
                <IdsIconButton
                  onClick={() => onFeedbackCorretly(true)}
                  type="button"
                  aria-label="curtir"
                  variant="secondary"
                >
                  {feedback ? 'curtir' : 'curtir_outline'}
                </IdsIconButton>
                <IdsIconButton
                  onClick={() => onFeedbackCorretly(false)}
                  aria-label="descurtir"
                  type="button"
                  variant="secondary"
                >
                  {feedback === false ? 'descurtir' : 'descurtir_outline'}
                </IdsIconButton>
              </div>
              <Navigation
                submit
                nextStep
                isNextButtonDisabled={feedback === null}
                nextButtonId="diagnosis-next-btn"
              />
            </form>
          </>
        }
      />
    </ProposalLayout>
  )
}

export default Diagnosis
