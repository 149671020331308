import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getDiagnosis = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'target-user-goal',
    method: 'get'
  })

  return data
}
const putDiagnosis = async ({ feedback }) => {
  const payload = feedback === null ? {} : { feedback }

  const { data } = await defaultLeadsApiCall({
    endpoint: 'feedback-goal',
    method: 'put',
    body: payload
  })

  return data
}

export default { getDiagnosis, putDiagnosis }
