import { useEffect, useState } from 'react'

import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import services from './services'

const useDiagnosis = ({ changeLeadData }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [feedback, setFeedback] = useState(null)
  const [diagnosis, setDiagnosis] = useState({})

  useEffect(() => {
    services.getDiagnosis().then((data) => {
      setDiagnosis(data)
      changeLeadData({ previousStep: data?.previous_step })
    })
  }, [])

  const onFeedbackCorretly = (option) => {
    setFeedback(option)
  }

  const onSubmit = async () => {
    setSubmitLoading(true)

    analytics.track('buttonClick', {
      currentPage: 'formulario-diagnosis',
      detail: 'click-step-diagnosis',
      customLayer: {
        description: `diagnosis-${diagnosis?.name}`
      }
    })

    return services
      .putDiagnosis({ feedback })
      .then(({ next_step }) => {
        changeLeadData({ feedback })
        goTo(next_step)
      })
      .finally(() => setSubmitLoading(false))
  }

  return {
    onSubmit,
    onFeedbackCorretly,
    feedback,
    diagnosis,
    submitLoading
  }
}

export default useDiagnosis
